import {
  SET_PROPERTY_GIFT_CARD_TYPE_LOADING,
  SET_PROPERTY_GIFT_CARD_TYPES,
  SET_STORE_GIFT_CARD_TYPE_LOADING,
  SET_STORE_GIFT_CARD_TYPES,
  SET_GIFT_CARD_LOADING,
  SET_GIFT_CARD,
  SET_GIFT_CARDS_LOADING,
  SET_GIFT_CARDS,
  SET_CREATED_GIFT_CARD_LOADING,
  SET_CREATED_GIFT_CARD,
  SET_UPDATE_GIFT_CARD_TYPE_LOADING,
  SET_AVAILABLE_UNIT_LOADING,
  SET_AVAILABLE_UNITS,
  SET_UNIT_LOADING,
  SET_UNIT,
  SET_GIFT_CARDS_BY_PROPERTY_ID_LOADING,
  SET_GIFT_CARDS_BY_PROPERTY_ID,
  SET_GIFT_CARDS_PURCHASES_LOADING,
  SET_GIFT_CARDS_PURCHASES,
  SET_PARAMS,
  SET_PROPERTY_INFO_LOADING,
  SET_PROPERTY_INFO,
  SET_UPDATE_GIFT_CARD_LOADING,
  SET_GIFT_CARD_TYPE_PHOTO_UPLOAD,
  SET_CREATED_GIFT_CARD_BOOKING_LOADING,
  SET_CREATED_GIFT_CARD_BOOKING,
  SET_GIFT_CARD_DETAILS_LOADING,
  SET_GIFT_CARD_DETAILS,
  SET_GIFT_CARD_TYPE_SIZES_LOADING,
  SET_GIFT_CARD_TYPE_SIZES,
  SET_GIFT_CARD_TYPE_SIZE_LOADING,
  SET_GIFT_CARD_TYPE_SIZE,
  SET_UPDATE_GIFT_CARD_BOOKING_LOADING,
  SET_GIFT_CARD_CHECKOUT_DATA_LOADING,
  SET_GIFT_CARD_PURCHASE_STATUS_LOADING,
  SET_GIFT_CARD_BOOKING_DETAILS,
  SET_GIFT_CARD_BOOKING_DETAILS_LOADING,
  SET_GIFT_CARD_CLOSED_DATES_DATA,
  SET_GIFT_CARD_CLOSED_DATES_LOADING,
  SET_GIFT_CARD_UNITS_DATA,
  SET_GIFT_CARD_UNITS_LOADING,
  SET_GIFT_CARD_PROPERTIES,
  SET_GIFT_CARD_PROPERTIES_LOADING,
  SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_LOADING,
  SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_DATA,
  SET_GIFT_CARD_PDF_TEMPLATES_LOADING,
  SET_GIFT_CARD_PDF_TEMPLATES_DATA,
  SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_LOADING,
  SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_DATA,
} from './property_gift_cards_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  giftCardTypes: EMPTY_ENTITY,
  storeGiftCardTypes: EMPTY_ENTITY,
  giftCard: EMPTY_ENTITY,
  giftCards: EMPTY_ENTITY,
  createdGiftCard: EMPTY_ENTITY,
  isUpdatingGiftCardType: false,
  isSettingGiftCardCheckoutData: false,
  isLoadingGiftCardPurchaseStatus: false,
  isLoadingGiftCardPurchases: false,
  propertyUnit: EMPTY_ENTITY,
  giftCardsByProperty: EMPTY_ENTITY,
  giftCardsPurchases: EMPTY_ENTITY,
  giftCardProperties: EMPTY_ENTITY,
  params: {
    children: 0,
    adults: 1,
    checkinDate: null,
    checkoutDate: null,
    propertyUnit: null,
    ratePlan: null,
    isUpsell: null,
    selectedGiftCardProperty: null,
  },
  propertyInfo: EMPTY_ENTITY,
  isUpdatingGiftCard: false,
  isUpdatingGiftCardBooking: false,
  giftCardTypePhotoUpload: EMPTY_ENTITY,
  giftCardBooking: EMPTY_ENTITY,
  giftCardDetails: EMPTY_ENTITY,
  giftCardTypeSizes: EMPTY_ENTITY,
  giftCardTypeSize: EMPTY_ENTITY,
  giftCardBookingDetails: EMPTY_ENTITY,
  giftCardClosedDates: EMPTY_ENTITY,
  giftCardUnitsInfo: EMPTY_ENTITY,
  hotelRoomTypeRatePlans: EMPTY_ENTITY,
  giftCardPdfTemplates: EMPTY_ENTITY,
  giftCardPdfTemplateFilledSections: EMPTY_ENTITY,
};

const actionHandlers = {
  [SET_PARAMS]: (state, action) => {
    return { ...state, params: action.payload };
  },

  [SET_GIFT_CARDS_BY_PROPERTY_ID_LOADING]: state => {
    const giftCardsByProperty = { ...state.giftCardsByProperty, isLoading: true };

    return { ...state, giftCardsByProperty };
  },

  [SET_GIFT_CARDS_BY_PROPERTY_ID]: (state, action) => {
    const giftCardsByProperty = { data: action.payload, isLoading: false };

    return { ...state, giftCardsByProperty };
  },

  [SET_GIFT_CARDS_PURCHASES_LOADING]: state => {
    const giftCardsPurchases = { ...state.giftCardsPurchases, isLoading: true };

    return { ...state, giftCardsPurchases };
  },

  [SET_GIFT_CARDS_PURCHASES]: (state, action) => {
    const giftCardsPurchases = { data: action.payload, isLoading: false };

    return { ...state, giftCardsPurchases };
  },

  [SET_UNIT_LOADING]: state => {
    const propertyUnit = { ...state.propertyUnit, isLoading: true };

    return { ...state, propertyUnit };
  },

  [SET_UNIT]: (state, action) => {
    const { data } = action.payload;
    const propertyUnit = { data, isLoading: false };

    return { ...state, propertyUnit };
  },

  [SET_AVAILABLE_UNIT_LOADING]: state => {
    const propertyUnit = { ...state.propertyUnit, isLoading: true };

    return { ...state, propertyUnit };
  },

  [SET_AVAILABLE_UNITS]: (state, action) => {
    const { data } = action.payload;
    const propertyUnit = { data, isLoading: false };

    return { ...state, propertyUnit };
  },

  [SET_UPDATE_GIFT_CARD_TYPE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingGiftCardType: params };
  },

  [SET_CREATED_GIFT_CARD_LOADING]: state => {
    const createdGiftCard = { ...state.createdGiftCard, isLoading: true };

    return { ...state, createdGiftCard };
  },

  [SET_CREATED_GIFT_CARD]: (state, action) => {
    const { data } = action.payload;
    const createdGiftCard = { data, isLoading: false };

    return { ...state, createdGiftCard };
  },

  [SET_GIFT_CARDS_LOADING]: state => {
    const giftCards = { ...state.giftCards, isLoading: true };

    return { ...state, giftCards };
  },

  [SET_GIFT_CARDS]: (state, action) => {
    const { data } = action.payload;

    const giftCards = { data, isLoading: false };

    return { ...state, giftCards };
  },

  [SET_GIFT_CARD_LOADING]: state => {
    const giftCard = { ...state.giftCard, isLoading: true };

    return { ...state, giftCard };
  },

  [SET_GIFT_CARD]: (state, action) => {
    const { data } = action.payload;

    const giftCard = { data, isLoading: false };

    return { ...state, giftCard };
  },

  // [SET_PROPERTY_GIFT_CARD_TYPE_LOADING]: state => {
  //   const giftCardTypes = { ...state.giftCardTypes, isLoading: true };

  //   return { ...state, giftCardTypes };
  // },

  [SET_PROPERTY_GIFT_CARD_TYPE_LOADING]: (state, { params }) => {
    const giftCardTypes = { ...state.giftCardTypes, isLoading: params };

    return { ...state, giftCardTypes };
  },

  [SET_PROPERTY_GIFT_CARD_TYPES]: (state, action) => {
    const { data } = action.payload;

    const giftCardTypes = { data, isLoading: false };

    return { ...state, giftCardTypes };
  },

  [SET_STORE_GIFT_CARD_TYPE_LOADING]: (state, { params }) => {
    const storeGiftCardTypes = { ...state.storeGiftCardTypes, isLoading: params };

    return { ...state, storeGiftCardTypes };
  },

  [SET_STORE_GIFT_CARD_TYPES]: (state, action) => {
    const { data } = action.payload;

    const storeGiftCardTypes = { data, isLoading: false };

    return { ...state, storeGiftCardTypes };
  },

  [SET_PROPERTY_INFO_LOADING]: state => {
    const propertyInfo = { ...state.propertyInfo, isLoading: true };

    return { ...state, propertyInfo };
  },

  [SET_PROPERTY_INFO]: (state, action) => {
    const { data } = action.payload;
    const propertyInfo = { data, isLoading: false };

    return { ...state, propertyInfo };
  },

  [SET_UPDATE_GIFT_CARD_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingGiftCard: params };
  },

  [SET_UPDATE_GIFT_CARD_BOOKING_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingGiftCardBooking: params };
  },

  [SET_GIFT_CARD_CHECKOUT_DATA_LOADING]: (state, { params }) => {
    return { ...state, isSettingGiftCardCheckoutData: params };
  },

  [SET_GIFT_CARD_PURCHASE_STATUS_LOADING]: (state, { params }) => {
    return { ...state, isLoadingGiftCardPurchaseStatus: params };
  },

  [SET_GIFT_CARD_BOOKING_DETAILS_LOADING]: (state, { params }) => {
    const giftCardBookingDetails = { ...state.giftCardBookingDetails, isLoading: params };

    return { ...state, giftCardBookingDetails };
  },

  [SET_GIFT_CARD_BOOKING_DETAILS]: (state, action) => {
    const giftCardBookingDetails = { data: action.payload, isLoading: false };

    return { ...state, giftCardBookingDetails };
  },

  [SET_GIFT_CARD_TYPE_PHOTO_UPLOAD]: (state, action) => {
    const giftCardTypePhotoUpload = { data: action.payload, isLoading: false };

    return { ...state, giftCardTypePhotoUpload };
  },

  [SET_CREATED_GIFT_CARD_BOOKING_LOADING]: (state, { params }) => {
    const giftCardBooking = { ...state.giftCardBooking, isLoading: params };

    return { ...state, giftCardBooking };
  },

  [SET_CREATED_GIFT_CARD_BOOKING]: (state, action) => {
    const giftCardBooking = { data: action.payload, isLoading: false };

    return { ...state, giftCardBooking };
  },

  [SET_GIFT_CARD_DETAILS_LOADING]: state => {
    const giftCardDetails = { ...state.giftCardDetails, isLoading: true };

    return { ...state, giftCardDetails };
  },

  [SET_GIFT_CARD_DETAILS]: (state, action) => {
    const { data } = action.payload;
    const giftCardDetails = { data, isLoading: false };

    return { ...state, giftCardDetails };
  },

  [SET_GIFT_CARD_TYPE_SIZES_LOADING]: state => {
    const giftCardTypeSizes = { ...state.giftCardTypeSizes, isLoading: true };

    return { ...state, giftCardTypeSizes };
  },

  [SET_GIFT_CARD_TYPE_SIZES]: (state, action) => {
    const { data } = action.payload;
    const giftCardTypeSizes = { data, isLoading: false };

    return { ...state, giftCardTypeSizes };
  },

  [SET_GIFT_CARD_TYPE_SIZE_LOADING]: state => {
    const giftCardTypeSize = { ...state.giftCardTypeSize, isLoading: true };

    return { ...state, giftCardTypeSize };
  },

  [SET_GIFT_CARD_TYPE_SIZE]: (state, action) => {
    const { data } = action.payload;
    const giftCardTypeSize = { data, isLoading: false };

    return { ...state, giftCardTypeSize };
  },

  [SET_GIFT_CARD_CLOSED_DATES_LOADING]: state => {
    const giftCardClosedDates = { ...state.giftCardClosedDates, isLoading: true };

    return { ...state, giftCardClosedDates };
  },
  [SET_GIFT_CARD_CLOSED_DATES_DATA]: (state, action) => {
    const giftCardClosedDates = { data: action.payload, isLoading: false };

    return { ...state, giftCardClosedDates };
  },

  [SET_GIFT_CARD_UNITS_LOADING]: state => {
    const giftCardUnitsInfo = { ...state.giftCardUnitsInfo, isLoading: true };

    return { ...state, giftCardUnitsInfo };
  },
  [SET_GIFT_CARD_UNITS_DATA]: (state, action) => {
    const giftCardUnitsInfo = { data: action.payload, isLoading: false };

    return { ...state, giftCardUnitsInfo };
  },
  [SET_GIFT_CARD_PROPERTIES]: (state, action) => {
    const giftCardProperties = { data: action.payload, isLoading: false };

    return { ...state, giftCardProperties };
  },
  [SET_GIFT_CARD_PROPERTIES_LOADING]: (state, { params }) => {
    const giftCardProperties = { ...state.giftCardProperties, isLoading: params };

    return { ...state, giftCardProperties };
  },
  [SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_DATA]: (state, action) => {
    const hotelRoomTypeRatePlans = { data: action.payload, isLoading: false };

    return { ...state, hotelRoomTypeRatePlans };
  },
  [SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_LOADING]: state => {
    const hotelRoomTypeRatePlans = { ...state.hotelRoomTypeRatePlans, isLoading: true };

    return { ...state, hotelRoomTypeRatePlans };
  },
  [SET_GIFT_CARD_PDF_TEMPLATES_LOADING]: state => {
    const giftCardPdfTemplates = { ...state.giftCardPdfTemplates, isLoading: true };

    return { ...state, giftCardPdfTemplates };
  },
  [SET_GIFT_CARD_PDF_TEMPLATES_DATA]: (state, action) => {
    const giftCardPdfTemplates = { data: action.payload, isLoading: false };

    return { ...state, giftCardPdfTemplates };
  },
  [SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_LOADING]: state => {
    const giftCardPdfTemplateFilledSections = { ...state.giftCardPdfTemplateFilledSections, isLoading: true };

    return { ...state, giftCardPdfTemplateFilledSections };
  },
  [SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_DATA]: (state, action) => {
    const giftCardPdfTemplateFilledSections = { data: action.payload, isLoading: false };

    return { ...state, giftCardPdfTemplateFilledSections };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
