export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    const cookie = cookieArr[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const setCookie = (name: string, value: string, expirationDays: number) => {
  const now = new Date();

  now.setTime(now.getTime() + expirationDays * 24 * 60 * 60 * 1000);

  const expires = now.toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/; SameSite=Strict`;
};
